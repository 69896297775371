button:disabled {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    mat-spinner {
      margin-inline-end: 8px;
    }
  }
}

button {
  transition: all $global-transition !important;
  border-radius: $btn-brd-radius !important;

  mat-icon {
    transition: color $global-transition;
  }
}

.prm-btn {
  color: $white-color !important;
  background-color: $prm-color !important;
}

.prm-btn .mdc-button__label {
  color: inherit !important;
}

.prm-btn:focus-within,
.prm-btn:hover {
  color: $white-color !important;
  background-color: $prm-hover-color !important;
}

.prm-btn:disabled {
  color: $prm-dis-clr-button !important;
  background-color: $prm-dis-color !important;
}

.dng-btn {
  color: $white-color;
  background-color: $dng-color !important;
}

.dng-btn:focus-within {
  color: $white-color;
  background-color: $dng-color !important;
}

.dng-btn:disabled {
  color: $dis-clr-button !important;
  background-color: $dng-dis-color !important;
}

mat-checkbox {
  color: $white-color;

  label {
    color: $white-color !important;

    .mat-mdc-checkbox-frame {
      border-color: $prm-color !important;
    }
  }
}

.mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
  background-color: $prm-color;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    background-color: $level3-gray !important;

    .div-sep {
      margin-inline: auto;
    }

    button {
      min-width: unset !important;
      min-height: unset !important;
      padding: 0 16px !important;
      color: white !important;

      span {
        color: inherit !important;
      }
    }

    button:disabled {
      color: rgb(219 219 219) !important;

      mat-icon,
      span {
        color: inherit !important;
      }
    }
  }
}

mat-form-field {
  margin-bottom: 20px !important;
  position: relative !important;
  min-width: unset !important;
  max-width: unset !important;

  .mat-button-base {
    position: absolute !important;
    bottom: -5px !important;
    right: -5px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-form-field-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    position: relative !important;
    background-color: $input-bg !important;
    border-radius: $form-field-brd-radius !important;
    transition: $global-transition border-color;
    border: 1px solid $form-field-brd-color !important;

    .mat-mdc-form-field-underline {
      background-color: none !important;
    }

    .mat-mdc-form-field-outline:hover {
      border-bottom: $prm-color !important;
    }

    label {
      color: $input-color-label !important;
      font-size: 17px !important;
    }

    input,
    textarea,
    .mat-mdc-select-value-text {
      color: $input-color-txt !important;
      font-size: 20px !important;
      margin-top: 1px !important;
      padding-inline-start: 5px !important;
    }

    input {
      max-width: calc(100% - 42px) !important;
      min-width: fit-content !important;
      padding: 0 !important;
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: unset !important;
    }
  }

  .mat-mdc-text-field-wrapper:hover {
    border-color: $input-hover-color !important;
  }

  .mat-mdc-text-field-wrapper:focus-within {
    border-color: $prm-color !important;
  }

  mat-select {
    .mat-mdc-select-arrow {
      color: $white-color;
    }
  }

  .option-btn {
    position: absolute;
    top: 5px;
    right: 0px;
    background-color: $input-bg !important;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-icon-button {
      color: $input-color-label !important;
    }
  }
}

.button-wrapper {
  input {
    display: none;
  }

  .mat-mdc-form-field-flex {
    height: 56px !important;
  }

  .button-g {
    position: absolute;
    top: -10px;
    right: 0px;

    button {
      padding: 0 !important;
      min-width: 44px !important;
      height: 32px !important;
      margin-inline-start: 10px !important;
    }
  }

  .btn-3 {
    button {
      width: 54px;
    }
  }

  .btn-2 {
    button {
      width: 80px;
    }
  }
}

.disabled-input {
  .mat-mdc-form-field-flex {
    background-color: black !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;

    .mat-mdc-form-field-flex {
      padding-inline: 1rem !important;
    }
  }

  input {
    text-align: center !important;
  }
}

mat-accordion {
  width: 100% !important;

  mat-expansion-panel {
    background-color: $input-bg !important;

    mat-expansion-panel-header {
      .mat-mdc-content {
        mat-panel-title {
          color: $white-color !important;
        }

        mat-panel-description {
          color: $card-txt-color !important;
        }
      }

      .mat-mdc-expansion-indicator::after {
        color: $white-color !important;
      }
    }
  }
}

.mat-mdc-select-panel {
  background-color: $level3-gray !important;

  // .mat-option-text {
  //   color: $input-color-txt !important;
  //   font-size: 18px !important;
  // }

  // .mat-optgroup-label {
  //   color: $input-color-label !important;
  // }
  mat-option {
    span {
      color: $input-color-txt !important;
      font-size: 18px !important;
    }
  }

  .mat-mdc-optgroup-label span {
    color: $input-color-label !important;
  }
}

.mat-mdc-form-field-ripple {
  background-color: transparent !important;
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
  background-color: transparent !important;
}

.mat-autocomplete-panel {
  background-color: $level3-gray !important;

  mat-option {
    color: $input-color-txt !important;
    font-size: 18px !important;
  }

  .mat-selected {
    background-color: #252526 !important;
  }
}

mat-spinner {
  svg {
    circle {
      stroke: $light-gray !important;
    }
  }
}

mat-icon,
mat-panel-title {
  color: $white-color !important;
}

mat-panel-description {
  color: $light-gray !important;
}

mat-list-item {
  color: $light-gray !important;
}

mat-divider,
.mat-form-field-flex {
  background-color: $level2-gray !important;
}

.mat-mdc-snack-bar-action {
  color: yellow !important;
}

mat-list-item {
  span {
    color: $light-gray !important;
  }
}

mat-divider

/*,
.mat-mdc-form-field-flex*/ {
  background-color: $level2-gray !important;
}

.mat-mdc-menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .div-sep {
    width: 92% !important;
  }

  .mat-mdc-menu-item {
    height: 2rem !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    min-width: 10rem !important;

    mat-icon {
      font-size: 1rem !important;
      height: fit-content !important;
      width: fit-content !important;
      margin: 0 !important;
    }

    span {
      font-size: 0.8rem !important;
      margin-inline-start: 8px !important;
    }
  }
}

/*-------Table style---------*/
.mat-elevation-z8 {
  min-width: 1200px;
  background-color: transparent !important;
  box-shadow: none !important;

  .empty-table {
    @extend .flex-center;

    background-color: $th-backcolor;
    color: $th-title-color;
    border: $th-border-size solid $th-border-color;
    height: 100px;

    p {
      font-size: 24px;
      margin: 0;
      color: inherit;
    }
  }

  table {
    width: 100%;
    min-width: inherit;
    background: none !important;

    thead {
      background-color: transparent !important;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .mat-row {
      height: 40px !important;
    }

    th {
      color: $th-title-color !important;
      background-color: $th-backcolor !important;
      border-bottom: none !important;
      border: $th-border-size solid $th-border-color !important;
      border-right-color: transparent !important;
      padding: $th-padding !important;
      padding-top: 14px !important;
      height: $th-height !important;
      font-size: $th-title-fsize !important;

      .mat-sort-header-content {
        margin-inline-start: 14px !important;
      }
    }

    th:first-child {
      width: 340px !important;
    }

    th:last-child {
      width: 432px !important;
    }

    th:nth-last-child(2) {
      border-right-color: $th-border-color !important;
    }

    .th-remove {
      display: none !important;
    }

    tr {
      height: 40px !important;
      background-color: $level5-gray !important;

      .mat-sort-header-container {
        display: flex;
        justify-content: center;
      }

      td {
        font-size: $td-font-size !important;
        font-weight: $td-font-weight !important;
        color: $white-color !important;
        background-color: $td-backcolor !important;
        border: $td-border-size solid $td-border-color !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        padding: 0 20px !important;
        width: 405px !important;

        .update-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .text-content {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-top: 4px;

          i {
            padding-inline-start: 8px !important;
          }
        }

        .clickable {
          cursor: pointer;
        }
      }

      td > div {
        @extend .flex-start;
      }

      .actions {
        mat-icon {
          color: inherit;
        }

        button:disabled {
          background-color: transparent !important;
        }
      }
    }

    tr:first-child > td {
      border-top: unset !important;
    }

    .td-remove {
      width: 40px !important;
      background-image: url(../../assets/images/table-remove-border.png);
      background-position: left;
      background-size: 100% 101%;
      background-repeat: no-repeat;
      padding: 0 !important;
      border: unset !important;

      .text-content {
        justify-content: center;

        i {
          cursor: pointer;
          padding-inline-start: 0px !important;
        }
      }
    }

    .h-start {
      .mat-sort-header-container {
        display: flex;
        justify-content: flex-start !important;
      }
    }
  }

  mat-paginator {
    font-size: 16px !important;
    margin-inline-end: 2.5rem !important;
    background-image: url(../../assets/images/pagein-border.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100% !important;
    width: calc(100% - 40px) !important;

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        margin-inline: 4px !important;
      }
    }

    .mat-mdc-paginator-page-size {
      @extend .flex-center;
    }

    mat-form-field {
      width: fit-content !important;
      margin-bottom: 0px !important;

      .mat-mdc-text-field-wrapper {
        width: 55px !important;
        height: 40px !important;
        padding: 0px !important;

        .mat-mdc-form-field-flex {
          @extend .flex-center;

          background-color: $pagein-selector-backcolor !important;
          border: $pagein-selector-border-size solid
            $pagein-selector-border-color !important;
          width: 100%;
          height: 100%;
        }

        .mat-mdc-form-field-infix {
          padding: 0 !important;
          border: none !important;

          mat-select {
            margin-top: 6px !important;
          }
        }
      }

      .mat-mdc-form-field-underline {
        height: 0 !important;
      }
    }
  }
}

//For dashboard
.dashboard-page {
  .mat-elevation-z8 {
    height: 29.5rem !important;
    min-height: 29.5rem !important;
    overflow-y: auto !important;
  }

  table {
    position: relative;
    min-width: 0 !important;

    tr {
      th {
        border-right-color: transparent !important;

        .mat-sort-header-container {
          width: 100% !important;
          justify-content: flex-start !important;
        }
      }

      th:last-child {
        border-right-color: $td-border-color !important;
      }

      td {
        //padding-inline-start: 20px !important;

        i {
          padding-inline-end: 7px !important;
        }
      }

      td > div {
        @extend .flex-start;
      }

      td:last-child {
        border-right-color: $td-border-color !important;
      }
    }
  }

  .expired-licenses-table {
    tr {
      td:last-child {
        color: #ff0000 !important;
        background-color: $th-backcolor !important;
        width: 210px !important;
        border-right-color: $td-border-color !important;

        p {
          text-align: center !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.table-controls {
  @extend .flex-center;

  align-content: stretch;
  margin-top: 50px;
  margin-bottom: 18px;

  .ng-star-inserted {
    display: flex;
    align-items: center;
  }

  button {
    width: 199px !important;
    height: 50px !important;
    border-radius: 4px !important;
    padding: 0px 12px !important;
    gap: 10px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 21px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    margin-inline-start: 20px !important;
    color: $white-color !important;
  }

  mat-form-field {
    width: 344px !important;
    min-height: 50px !important;
    margin-bottom: 0 !important;
    color: #bbbcbf !important;

    i {
      margin-inline-end: 8px;
    }

    .mat-mdc-text-field-wrapper {
      height: 54px !important;
    }

    // .mat-mdc-form-field-flex {
    //   padding: 4px !important;
    //   padding-inline-start: 16px !important;
    // }

    mat-label {
      @extend .flex-start;

      font-size: 20px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #bbbcbf !important;

      mat-icon {
        margin-inline-end: 8px !important;
        font-size: 26px !important;
      }
    }
  }
}

::placeholder {
  color: #828282 !important;
  opacity: 1 !important;
}

@media (max-width: 1400px) {
  .mat-elevation-z8 {
    min-width: 1297px !important;
  }

  .mat-paginator-container {
    padding: 0 50px !important;
  }
}

// .location-table table thead {
//   top: 0 !important;
// }
/*------------------------------*/

.not-checked,
.not-checked span {
  color: $level1-red !important;
}

.checked,
.checked span {
  color: $level1-green !important;
}

mat-paginator {
  background-color: transparent !important;
  color: $light-gray !important;

  .mat-mdc-paginator-range-actions {
    button {
      background-color: transparent !important;
      color: $white-color !important;

      .mat-mdc-paginator-icon {
        fill: unset !important;
      }
    }

    button:disabled {
      color: $prm-dis-clr-button !important;
    }
  }

  mat-form-field {
    .mat-mdc-form-field-flex {
      border: unset !important;
      padding: 0 6px !important;
    }
  }
}

mat-bottom-sheet-container {
  background-color: $snav-bg-color !important;
  max-height: 40vh !important;
  min-height: 10vh !important;
  position: relative;

  mat-list {
    padding-top: 30px !important;
  }

  .mat-mdc-list-item-content {
    color: $white-color !important;
  }

  button {
    color: $card-txt-color !important;
  }

  .header-bottonsheet {
    position: fixed;
    top: -1px;
    left: -1px;
    z-index: 9;
    width: 100%;
    height: 32px;

    h3 {
      position: absolute;
      font-size: 20px;
      font-weight: 700;
      left: 10px;
      top: 0;
      color: white;
      height: 25px;
      margin: 0px;
    }

    .close-btn {
      min-width: 32px !important;
      padding: 0 !important;
    }
  }
}

mat-tab-header {
  .mat-tab-label {
    color: $white-color;
    opacity: 1;
  }
}

.search-input {
  margin: 0;

  mat-icon {
    font-size: 1.4rem !important;
    margin-inline-end: 0.6rem;
  }

  mat-label {
    display: flex;
    align-items: center;
  }
}

.loader-btn {
  mat-spinner svg circle {
    stroke: $prm-dis-clr-button !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
  -webkit-text-fill-color: $input-color-txt !important;
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

//---------Radio button----------------
.radio-group-wrapper {
  text-align: start;
  margin-top: 20px;
  margin-bottom: 12px;
  padding-inline: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  .mat-mdc-radio-label {
    .mat-mdc-radio-container {
      @extend .flex-center;

      width: 26px;
      height: 26px;
      border: 2px solid $radio-brd-color;
      border-radius: 50%;

      .mat-mdc-radio-inner-circle {
        width: 18px !important;
        height: 18px !important;
        position: relative !important;
        background-color: $prm-color !important;
        border-color: transparent !important;
      }

      .mat-mdc-radio-outer-circle {
        position: relative !important;
        background-color: transparent !important;
        border-color: transparent !important;
        display: none !important;
      }
    }
  }

  mat-radio-button {
    margin-inline-end: 24px;
  }
}

.mat-mdc-radio-checked .mat-mdc-radio-inner-circle {
  transform: scale(1) !important;
}
//--------------------------------------------

//------------------Toggle switch-----------
mat-slide-toggle {
  .mdc-form-field {
    width: 36px !important;
    height: 24px !important;
    border-radius: 14px !important;
    background-color: #828282 !important;

    button {
      background-color: transparent !important;

      .mdc-switch__track {
        display: none !important;
      }

      .mdc-switch__handle {
        width: 20px !important;
        height: 20px !important;
        background-color: white !important;

        .mdc-switch__icons {
          display: none !important;
        }
      }

      .mdc-switch__handle::after,
      .mdc-switch__handle::before,
      .mdc-switch__icons::after {
        display: none !important;
      }
    }
  }

  .mat-mdc-slide-toggle-thumb-container {
    top: 2px;

    .mat-mdc-slide-toggle-thumb {
    }
  }
}

.mat-mdc-slide-toggle-checked .mdc-form-field {
  background-color: $prm-color !important;
}

.clickable {
  cursor: pointer;
}

.fs-sm-yellow-icon {
  background-color: #ffc700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  width: 24px;
  height: 24px;
}

//----------------toggle switch--------------
.toggle-switch-field {
  width: 182px;
  height: 36px;
  border-radius: 4px;
  background-color: #252526;
  border: 1px solid rgba(133, 133, 133, 0.8);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline-start: 3px;
  //padding: 0 10px;
  margin-bottom: 8px;

  i {
    height: 24px;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: #bdbdbd;
    //margin-inline-end: 8px;
  }
}

.switch-button-wrapper {
  margin-bottom: 8px;
}

.toggle-switch-active {
  i svg path {
    fill: #ffc700 !important;
  }

  span {
    color: white !important;
  }
}

.alert-container {
  font-size: 30px;
  width: auto;
  padding: 26px 16px;

  mat-label {
    color: red;
  }
}

.json-formatter-key {
  color: #aaaaaf !important;
}
